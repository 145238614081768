import { render, staticRenderFns } from "./PinForm.vue?vue&type=template&id=4942e262&scoped=true&"
import script from "./PinForm.vue?vue&type=script&lang=js&"
export * from "./PinForm.vue?vue&type=script&lang=js&"
import style0 from "./PinForm.vue?vue&type=style&index=0&id=4942e262&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4942e262",
  null
  
)

export default component.exports
import {QInput,QSelect,QImg,QFile,QIcon,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInput,QSelect,QImg,QFile,QIcon,QBtn})
