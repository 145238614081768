import { render, staticRenderFns } from "./EventForm.vue?vue&type=template&id=0905cc2e&scoped=true&"
import script from "./EventForm.vue?vue&type=script&lang=js&"
export * from "./EventForm.vue?vue&type=script&lang=js&"
import style0 from "./EventForm.vue?vue&type=style&index=0&id=0905cc2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0905cc2e",
  null
  
)

export default component.exports
import {QInput,QIcon,QPopupProxy,QDate,QBtn,QTime,QField,QSelect,QCarousel,QCarouselSlide,QCarouselControl,QFile,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInput,QIcon,QPopupProxy,QDate,QBtn,QTime,QField,QSelect,QCarousel,QCarouselSlide,QCarouselControl,QFile})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
