//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { required, url, minLength, email, numeric } from "vuelidate/lib/validators";
import axios from 'axios';

const { mapFields } = createHelpers({
  getterType: "pins/getField",
  mutationType: "pins/updateField",
});

const cepNotFound = (value, vm) => (value && vm.cepNotFound == false);

export default {
  name: "PinProfile",
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
    },
    fetch: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      waiting: false,
      valid: true,
      active: false,
      files: null,
      file: null,
      cepNotFound: false
    };
  },
  validations: {
    categoryId: {
      required,
    },
    title: {
      required,
    },
    email: {
      email,
    },
    phone: {
      minLength: minLength(11),
    },
    street: {
      required,
    },
    number: {
      numeric
    },
    neighborhood: {
      required,
    },
    city: {},
    cep: {
      required,
      minLength: minLength(8),
      cepNotFound
    },
    description: {
      required,
    },
    link: {
      url,
    },
    facebook: {
      url,
    },
    instagram: {
      url,
    },
    twitter: {
      url,
    },
    whatsapp: {},
    files: {},
  },
  computed: {
    ...mapGetters({
      categories: "categories/loadCategories",
    }),
    ...mapFields({
      category: "categorySelected",
      categoryId: "pinForm.categoryId",
      title: "pinForm.title",
      email: "pinForm.email",
      phone: "pinForm.phone",
      street: "pinForm.street",
      number: "pinForm.number",
      neighborhood: "pinForm.neighborhood",
      city: "pinForm.city",
      cep: "pinForm.zipcode",
      description: "pinForm.description",
      images: "pinForm.imageIds",
      link: "pinForm.link",
      facebook: "pinForm.facebook",
      instagram: "pinForm.instagram",
      twitter: "pinForm.twitter",
      whatsapp: "pinForm.whatsapp",
    }),
    categoryErrorMessage() {
      if (!this.$v.categoryId.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    titleErrorMessage() {
      if (!this.$v.title.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    emailErrorMessage() {
      if (!this.$v.email.email) {
        return "Entre com um email válido";
      }
      return "";
    },
    phoneErrorMessage() {
      if (!this.$v.phone.minLength) {
        return "Entre com um telefone válido";
      }
      return "";
    },
    streetErrorMessage() {
      if (!this.$v.street.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    numberErrorMessage() {
      return "";
    },
    neighborhoodErrorMessage() {
      if (!this.$v.neighborhood.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    cityErrorMessage() {
      if (!this.$v.city.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    cepErrorMessage() {
      if (!this.$v.cep.required) {
        return "Esse campo é obrigatório";
      } else if (!this.$v.cep.minLength) {
        return "Entre com um cep válido";
      } else if (cepNotFound) {
        return "Cep não encontrado"
      }
      return "";
    },
    descriptionErrorMessage() {
      if (!this.$v.description.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    linkErrorMessage() {
      if (!this.$v.link.url) {
        return "Entre com uma url válida";
      }
      return "";
    },
    facebookErrorMessage() {
      if (!this.$v.facebook.url) {
        return "Entre com uma url válida";
      }
      return "";
    },
    instagramErrorMessage() {
      if (!this.$v.instagram.url) {
        return "Entre com uma url válida";
      }
      return "";
    },
    twitterErrorMessage() {
      if (!this.$v.twitter.url) {
        return "Entre com uma url válida";
      }
      return "";
    },
    whatsappErrorMessage() {
      return "";
    },
    filesErrorMessage() {
      if (!this.$v.files.required) {
        return "É necessário uma imagem.";
      }
      return "";
    },
    // mascara para telefone
    phoneMask() {
      if (this.phone === null || this.phone === undefined) {
        return false;
      }
      let str = "";
      const p = this.phone;
      const ddd = p.slice(0, 2);
      const prefix = p.slice(2, 7);
      const sufix = p.slice(7, 11);
      str = str.concat(ddd).concat("").concat(prefix).concat(" ").concat(sufix);
      return str;
    },
  },
  created() {
    // if(!this.editMode) {this.cleanForm() && this.$v.$reset();} 
    if(this.$route.params.action === "edit") {
      this.fetchStorage();
    } else if (this.$route.params.action !== "edit") {
      this.cleanForm() && this.$v.$reset();
    }
  },
  methods: {
    sendForm() {
      if(this.editMode) {
        this.$store.dispatch("pins/putPin", { $router: this.$router });
        return
      }

      this.$store.dispatch("pins/postPin", { $router: this.$router })
    },
    confirmCreate() {
      this.waiting = true;
      this.$v.$touch();
      if (!this.$v.$anyError) {
        if (this.waiting) {
          this.$q.notify({
            message: "Por favor, aguarde.",
            position: 'top-right',
            timeout: 1500
          });
        }

        //se tiver imagens
        if (this.files) {
          this.$store
            .dispatch("images/uploadArray", { files: this.files })
            .then((fileIds) => {
              this.images = fileIds;
              this.sendForm();
            })
            .catch((error) => {
              this.waiting = false;
            });
        }
        //se não tiver imagens
        else {
          this.sendForm();
        }

      } else {
        this.$q.notify({
          message: "Por favor, preencha os campos corretamente.",
          position: 'top-right',
        });
      }
    },
    updateFiles(files) {
      if (Array.isArray(files) === false || files.length === 0) {
        this.files = null;
      }
      else if (Array.isArray(this.files) === true) {
        const diff = this.files.filter((file) => files.indexOf(file) === -1);

        if (diff.length === 1 && this.files.length > 1) {
          this.files = files.slice();
        }
        else if (this.files.length < 3) {
          this.files = diff.concat(files);
        }
      }
      else {
        this.files = files.slice();
      }
    },
    cleanForm() {
      this.category = '';
      this.categoryId = null;
      this.title = '';
      this.email = '';
      this.phone = '';
      this.street = '';
      this.number = '';
      this.neighborhood = '';
      this.city = '';
      this.cep = '';
      this.description = '';
      this.images = [];
      this.link = '';
      this.facebook = '';
      this.instagram = '';
      this.twitter = '';
      this.whatsapp = '';
    },

    async searchAddress() {
      var self = this;
      self.cepNotFound = false;
      
      if(/^[0-9]{8}$/.test(this.cep)){

        const addressData = await axios.get(("https://viacep.com.br/ws/" + this.cep + "/json/"))

          if(addressData.data.erro){
            this.$refs.inputNumber.focus();
            self.cepNotFound = true;
            
            self.street = '';
            self.neighborhood = '';
            self.city = '';
            return;
          } 

          self.street = addressData.data.logradouro;
          self.neighborhood = addressData.data.bairro;
          self.city = addressData.data.localidade;
          this.$refs.inputNumber.focus();
      }
    },
    fetchStorage() {
      const idToUpdate = this.$route.params.id ? this.$route.params.id : this.$store.state.pins.selectedPinId;
      const info = this.$store.getters["pins/getPinById"](idToUpdate);
      this.categoryId = info.categoryId;
      this.category = this.$store.getters["categories/getCategoryById"](
        this.categoryId
      );
      this.title = info.title;
      this.email = info.email;
      this.phone = info.phone;
      this.number = info.number;
      this.street = info.street;
      this.neighborhood = info.neighborhood;
      this.city = info.city;
      this.cep = info.zipcode;
      this.description = info.description;
      this.link = info.link;
      this.facebook = info.facebook;
      this.instagram = info.instagram;
      this.images = info.imageIds;
    },
  }
}
