//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { required, url, minLength, numeric, requiredIf, helpers } from "vuelidate/lib/validators";
import { Money } from "v-money";
import { QField } from "quasar";
import axios from 'axios';

const { mapFields } = createHelpers({
  getterType: "events/getField",
  mutationType: "events/updateField",
});

const zipcodeNotFound = (value, vm) => !helpers.req(value) || (value && vm.zipcodeNotFound == false);

export default {
  name: "EventProfile",
  components: {
    Money,
    QField,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      waiting: false,
      files: null,
      file: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        maxlength: 14,
        masked: false,
      },
      autoplay: true,
      slide: 1,
      zipcodeNotFound: false
    };
  },
  validations: {
    categoryId: {
      required,
    },
    name: {
      required,
    },
    date: {
      required,
    },
    time: {
      required,
    },
    local: {
      required,
    },
    street: {},
    neighborhood: {},
    number: {
      numeric,
    },
    zipcode: {
      minLength: minLength(8),
      zipcodeNotFound,
      required: requiredIf(function() {
        return this.zipcode.length;
      })
    },
    description: {
      required,
    },
    link: {
      url,
    },
    files: {},
  },
  computed: {
    ...mapFields({
      category: "categorySelected",
      categoryId: "eventForm.categoryId",
      name: "eventForm.title",
      date: "eventForm.date",
      time: "eventForm.time",
      ticket: "eventForm.ticket",
      local: "eventForm.local",
      street: "eventForm.street",
      neighborhood: "eventForm.neighborhood",
      number: "eventForm.number",
      city: "eventForm.city",
      zipcode: "eventForm.zipcode",
      description: "eventForm.description",
      link: "eventForm.link",
      images: "eventForm.imageIds",
    }),
    ...mapGetters({
      categories: "categories/loadCategories",
      user: "users/getCurrentUser",
    }),
    categoryErrorMessage() {
      if (!this.$v.categoryId.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    nameErrorMessage() {
      if (!this.$v.name.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    dateErrorMessage() {
      if (!this.$v.date.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    timeErrorMessage() {
      if (!this.$v.time.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    localErrorMessage() {
      if (!this.$v.local.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    streetErrorMessage() {
      return "";
    },
    neighborhoodErrorMessage() {
      return "";
    },
    numberErrorMessage() {
      return "";
    },
    zipcodeErrorMessage() {
      if (!this.$v.zipcode.minLength) {
        return "Entre com um CEP válido";
      } else if (zipcodeNotFound) {
        return "CEP não encontrado"
      }      
      return "";
    },
    descriptionErrorMessage() {
      if (!this.$v.description.required) {
        return "Esse campo é obrigatório";
      }
      return "";
    },
    linkErrorMessage() {
      if (!this.$v.link.url) {
        return "Entre com uma url válida";
      }
      return "";
    },
    filesErrorMessage() {
      return "";
    },
  },
  created() {
    // if (!this.editMode) this.cleanForm() && this.$v.$reset();
    if(this.$route.params.action === "edit") {
      this.fetchStorage();
    } else if (this.$route.params.action !== "edit") {
      this.cleanForm() && this.$v.$reset();
    }
  },
  methods: {
    updateFiles(files) {
      if (Array.isArray(files) === false || files.length === 0) {
        this.files = null;
      } else if (Array.isArray(this.files) === true) {
        const diff = this.files.filter((file) => files.indexOf(file) === -1);
        if (diff.length === 1 && this.files.length > 1) {
          this.files = files.slice();
        } else if (this.files.length < 3) {
          this.files = diff.concat(files);
        }
      } else {
        this.files = files.slice();
      }
    },
    sendForm() {
      if (this.editMode) {
        this.$store.dispatch("events/updateEvent", { $router: this.$router });
      } else {
        this.$store.dispatch("events/createNewEvent", {
          $router: this.$router,
        });
      }

      this.waiting = false;
    },
    cleanForm() {
      this.date = "";
      this.time = "";
      this.ticket = "";
      this.site = "";
      this.street = "";
      this.neighborhood = "";
      this.number = "";
      this.city = "";
      this.zipcode = "";
      this.description = "";
      this.link = "";
      this.imgUrl = "";
      this.category = "";
      this.categoryId = null;
      this.name = "";
      this.local = "";
      this.images = [];
    },
    confirmCreate() {
      this.waiting = true;
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$q.notify({
          message: "Por favor, aguarde.",
          position: "top-right",
          timeout: 1500,
        });

        //se tiver imagens
        if (this.files) {
          this.$store
            .dispatch("images/uploadArray", { files: this.files })
            .then((fileIds) => {
              this.images = fileIds;
              this.sendForm();
            })
            .catch((error) => {
              this.waiting = false;
            });
        }
        //se não tiver imagens
        else {
          this.sendForm();
        }
      } else {
        this.$q.notify({
          message: "Por favor, preencha os campos corretamente.",
          position: "top-right",
        });
      }
    },
    async searchAddress() {
      var self = this;
      self.zipcodeNotFound = false;
      
      if(/^[0-9]{8}$/.test(this.zipcode)){

        const addressData = await axios.get(("https://viacep.com.br/ws/" + this.zipcode + "/json/"))

          if(addressData.data.erro){
            this.$refs.inputNumber.focus();
            self.zipcodeNotFound = true;
            
            self.street = '';
            self.neighborhood = '';
            self.city = '';
            return;
          } 

          self.street = addressData.data.logradouro;
          self.neighborhood = addressData.data.bairro;
          self.city = addressData.data.localidade;
          this.$refs.inputNumber.focus();
      }
    },

    fetchStorage() {
      const info = this.$store.state.events.currentEvent;
      const date = new Date(this.$store.state.events.currentEvent.dateTime);
      const dateInfo = date.toLocaleDateString();
      const timeInfo = date.toLocaleTimeString();

      this.categoryId = info.categoryId;
      this.category = this.$store.getters["categories/getCategoryById"](
        this.categoryId
      );
      this.imageIds = info.imageIds;
      this.name = info.title;
      this.date = dateInfo;
      this.time = timeInfo;
      this.street = info.street;
      this.neighborhood = info.neighborhood;
      this.number = info.number;
      this.zipcode = info.zipcode;
      this.city = info.city;
      this.ticket = info.ticket;
      this.link = info.link;
      this.local = info.local;
      this.description = info.description;
    },
    
  },
};
