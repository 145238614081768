//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "topics/getField",
  mutationType: "topics/updateField",
});

const hasCategory = (category) => category !== null;

export default {
  name: 'TopicForm',
  props: {
    editMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
        options: [],
        categoryField: '',
    };
  },
  validations: {
    title: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(100),
    },
    content: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(2000),
    },
    category: {
      hasCategory
    }
  },
  computed: {
    ...mapFields({
      title: "topicForm.title",
      category: "topicForm.categoryId",
      categoriesTagged: "topicForm.categoriesTagged",
      content: "topicForm.content",
    }),
    ...mapGetters({
      categories: "categories/loadCategories",
    }),
    currentCategoriesTagged() {
      let categoriesTaggedArray = []
        if(this.categoriesTagged.length > 0) {
          this.categoriesTagged.forEach(element => {
              categoriesTaggedArray.push(this.$store.getters['categories/getCategoryById'](element))
          })
        }
      return categoriesTaggedArray
    },
    titleErrorMessage() {
      if (!this.$v.title.required) {
        return "Campo obrigatório";
      }
      if (!this.$v.title.minLength) {
        return "Mínimo de 5 caracteres";
      }
      if (!this.$v.title.maxLength) {
        return "Máximo de 100 caracteres";
      }
      return "";
    },
    contentErrorMessage() {
      if (!this.$v.content.required) {
        return "Campo obrigatório";
      }
      if (!this.$v.content.minLength) {
        return "Mínimo de 5 caracteres";
      }
      if (!this.$v.content.maxLength) {
        return "Mínimo de 2000 caracteres";
      }
      return "";
    },
    termsErrorMessage() {
      return "É necessário aceitar os termos";
    },
    categoryErrorMessage() {
      return "É necessário escolher um tema";
    },
  },
  created() {
    this.options = [...this.categories];   
  },
  mounted() {
    if (this.editMode == false) {
      this.title = '',
      this.category = null,
      this.categoriesTagged = [],
      this.content = ''
    }

    if(this.$route.params.action === "edit") {
      this.fetchStorage();
    }
  },
  methods: {
    sanitize(value) {
      this.content = this.$sanitize(value);
    },
    tagCategory(category) {
      if ((this.category === null) && (!(this.categoriesTagged.includes(category)))) {
          this.category = category;
      } else if((category.id !== this.category.id) && (!this.categoriesTagged.includes(category))) {
        let catArray = this.categoriesTagged;
        catArray.push(category);
        this.categoriesTagged = catArray;
      }
    },
    untagCategory(element) {
      if(element === this.category) {
        this.category = null;
      } else {
        this.categoriesTagged.splice(element, 1);
      }

    },
    updateTopic() {
      this.$v.$touch();
      if(!this.$v.$anyError) {
        this.$store.dispatch("topics/updateTopic", { $socket: this.$socket })
      }
      else {
        this.$q.notify({
          message: "Por favor, preencha todos os campos para o debate",
          position: 'top-right',
        });
      }
    },
     fetchStorage() {
      this.$store.dispatch('topics/fetchStorage');
    },
  }
}

